// src/App.js
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { Authenticator } from '@aws-amplify/ui-react'; 
import '@aws-amplify/ui-react/styles.css';
import AuthenticatedApp from './components/AuthenticatedApp';
import './App.css';
import './config/aws-config'; // Ensure AWS Amplify is configured

const App = () => {
  return (
    <HelmetProvider>
      <Authenticator>
        <AuthenticatedApp />
      </Authenticator>
    </HelmetProvider>
  );
};

export default App;
