// src/components/AuthenticatedApp.js
import React, { useState, useEffect } from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';
import MainLayout from './MainLayout';
import EditThreshold from './EditThreshold';
import CheckThreshold from './CheckThreshold';
import SignOutButton from './SignOutButton';
import { fetchAuthSession } from 'aws-amplify/auth';  // Correct import for fetching session

const AuthenticatedApp = () => {
  const { user } = useAuthenticator((context) => [context.user]); 
  const [activeTab, setActiveTab] = useState('tab1');
  const [email, setEmail] = useState('');
  const [threshold, setThreshold] = useState('');
  const [message, setMessage] = useState('');
  const [currentThreshold, setCurrentThreshold] = useState(null);

  useEffect(() => {
    setEmail('');
    setThreshold('');
    setMessage('');
    setCurrentThreshold(null);
  }, [activeTab]);

  const fetchToken = async () => {
    try {
      const session = await fetchAuthSession();
      const token = session.tokens.idToken;

      if (!token) {
        console.error('Token is not available in session:', session);
        return null;
      }

      return token;
    } catch (error) {
      console.error('Error fetching Cognito token:', error);
      return null;
    }
  };

  const handleAdd = async () => {
    const token = await fetchToken();
    if (!token) {
      setMessage('You need to sign in first.');
      return;
    }

    try {
      const response = await fetch('https://7slo1sv5n2.execute-api.us-west-2.amazonaws.com/production/edit-threshold', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({ email, threshold }),
      });

      if (!response.ok) {
        throw new Error('Failed to update threshold');
      }

      const data = await response.json();
      setMessage(data.message || 'Threshold updated successfully!');
    } catch (error) {
      console.error('Error updating threshold:', error);
      setMessage('Failed to update threshold.');
    }
  };

  const handleCheck = async () => {
    const token = await fetchToken();
    if (!token) {
      setCurrentThreshold('You need to sign in first.');
      return;
    }

    try {
      const response = await fetch('https://7slo1sv5n2.execute-api.us-west-2.amazonaws.com/production/check-threshold', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}` 
        },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch threshold');
      }

      const data = await response.json();
      setCurrentThreshold(`${email} current threshold is ${data.threshold} hours`);
    } catch (error) {
      console.error('Error checking threshold:', error);
      setCurrentThreshold('Failed to retrieve threshold.');
    }
  };

  return (
    <MainLayout title="Clockify Alarm Management" description="Manage your Clockify alarms with ease">
      <div className="App">
        <div className="tabs">
          <button
            className={activeTab === 'tab1' ? 'active' : ''}
            onClick={() => setActiveTab('tab1')}
          >
            Edit Threshold
          </button>
          <button
            className={activeTab === 'tab2' ? 'active' : ''}
            onClick={() => setActiveTab('tab2')}
          >
            Check Threshold
          </button>
        </div>

        {activeTab === 'tab1' && (
          <EditThreshold
            email={email}
            setEmail={setEmail}
            threshold={threshold}
            setThreshold={setThreshold}
            handleAdd={handleAdd}
            message={message}
          />
        )}

        {activeTab === 'tab2' && (
          <CheckThreshold
            email={email}
            setEmail={setEmail}
            currentThreshold={currentThreshold}
            handleCheck={handleCheck}
          />
        )}

        <SignOutButton />
      </div>
    </MainLayout>
  );
};

export default AuthenticatedApp;
