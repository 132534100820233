// src/components/SignOutButton.js
import React from 'react';
import { useAuthenticator } from '@aws-amplify/ui-react';

const SignOutButton = () => {
  const { signOut } = useAuthenticator();

  return (
    <button onClick={signOut}>
      Sign Out
    </button>
  );
};

export default SignOutButton;
