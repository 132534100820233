// src/components/MainLayout.js
import React from 'react';
import { Helmet } from 'react-helmet-async';

const MainLayout = ({ title, description, children }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
    </Helmet>
    <main>
      {children}
    </main>
  </>
);

export default MainLayout;
