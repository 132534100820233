// src/components/CheckThreshold.js
import React from 'react';
import InputField from './InputField';

const CheckThreshold = ({ email, setEmail, currentThreshold, handleCheck }) => (
  <div className="tab-content">
    <h2>Check Threshold</h2>
    <form onSubmit={(e) => { e.preventDefault(); handleCheck(); }}>
      <InputField
        id="check-email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="User Email"
      />
      <button type="submit">Check</button>
    </form>
    {currentThreshold && <div className="result">{currentThreshold}</div>}
  </div>
);

export default CheckThreshold;
