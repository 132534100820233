// src/components/EditThreshold.js
import React from 'react';
import InputField from './InputField';

const EditThreshold = ({ email, setEmail, threshold, setThreshold, handleAdd, message }) => (
  <div className="tab-content">
    <h2>Edit Threshold</h2>
    <form onSubmit={(e) => { e.preventDefault(); handleAdd(); }}>
      <InputField
        id="email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        label="User Email"
      />
      <InputField
        id="threshold"
        type="number"
        value={threshold}
        onChange={(e) => setThreshold(e.target.value)}
        label="Threshold Hours"
      />
      <button type="submit">Edit</button>
    </form>
    {message && <div className="result">{message}</div>}
  </div>
);

export default EditThreshold;
