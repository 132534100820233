// src/components/InputField.js
import React from 'react';

const InputField = ({ id, type, value, onChange, label }) => (
  <div>
    <label htmlFor={id}>{label}:</label>
    <input
      type={type}
      id={id}
      value={value}
      onChange={onChange}
    />
  </div>
);

export default InputField;
